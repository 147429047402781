import type { ProductListingCarouselFragment } from '@ori/editorial-fetching';

export const editorialCarouselMock: ProductListingCarouselFragment['editorials'] = [
  {
    __typename: 'EditorialPage',
    description: 'Description of Icons',
    pageId: '/campaigns/editorials/icons',
    title: 'Icons',
    thumbnailUrl:
      'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=28699c27-ca65-4e53-938e-757e0722f552&name=Bestseller-banner-gif_470x764&inputFormat=gif',
    themeTag: 'Editorials',
  },
  {
    __typename: 'EditorialPage',
    description: 'Description of Introduction',
    pageId: '/campaigns/editorials/introduction',
    title: 'Introduction',
    thumbnailUrl:
      'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=c85fe5e7-208d-4e09-a181-38fe80f43605&name=novage-plus&inputFormat=png',
    themeTag: 'Editorials',
  },
  {
    __typename: 'EditorialPage',
    description: 'Description of Blemish Age Defy Routine',
    pageId: '/campaigns/editorials/novage-plus-blemish-age-defy-routine',
    title: 'Blemish Age Defy Routine',
    thumbnailUrl:
      'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=a30c5c55-4aa4-4417-98be-f96dc3cd0165&name=Blemish-age-defy&inputFormat=jpg',
    themeTag: 'Editorials',
  },
  {
    __typename: 'EditorialPage',
    description: 'Description of Bright Intense Routine',
    pageId: '/campaigns/editorials/novage-plus-bright-intense-routine',
    title: 'Bright Intense Routine',
    thumbnailUrl:
      'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=ee15dbbc-9d0e-48b1-8a82-1c5e26f13b45&name=BrightIntense_ThumbNail_200x200_X2&inputFormat=jpg',
    themeTag: 'Editorials',
  },
  {
    __typename: 'EditorialPage',
    description: 'Description of Lift and Firm Routine',
    pageId: '/campaigns/editorials/novage-plus-lift-and-firm-routine',
    title: 'Lift and Firm Routine',
    thumbnailUrl:
      'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=0cf8327c-5d12-4abf-9969-6c177bb2061d&name=Novage-plus-routines2-1&inputFormat=jpg',
    themeTag: 'Editorials',
  },
  {
    __typename: 'EditorialPage',
    description: 'Description of Loyalty Program',
    pageId: '/campaigns/editorials/novageplusloyaltyprogram',
    title: 'Loyalty Program',
    thumbnailUrl:
      'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=d49e266a-d718-4465-9244-4960258a8b92&name=LoyaltyProgram_ThumbNail_200x200_X2&inputFormat=jpg',
    themeTag: 'Editorials',
  },
  {
    __typename: 'EditorialPage',
    description: 'Description of Novage Plus Routines',
    pageId: '/campaigns/editorials/novage-plus-routines',
    title: 'Novage Plus Routines',
    thumbnailUrl:
      'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=1142ef7a-5618-40d7-b774-adf7796b1259&name=Routine_ThumbNail_200x200_X2&inputFormat=jpg',
    themeTag: 'Editorials',
  },
  {
    __typename: 'EditorialPage',
    description: 'Description of Skinfluencer',
    pageId: '/campaigns/editorials/novage-plus-skinfluencer',
    title: 'Skinfluencer',
    thumbnailUrl:
      'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=a0fb3a1d-764a-41fa-a01b-d769a8f99151&name=Skinfluencer_Option1_ThumbNail_200x200_X2&inputFormat=png',
    themeTag: 'Editorials',
  },
  {
    __typename: 'EditorialPage',
    description: 'Description Wrinkle Smooth',
    pageId: '/campaigns/editorials/novage-plus-wrinkle-smooth-routine',
    title: 'Wrinkle Smooth',
    thumbnailUrl:
      'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=c5b127f9-86bf-4882-8297-2b9a6ae3e57b&name=WrinkleSmooth_ThumbNail_200x200_X2&inputFormat=jpg',
    themeTag: 'Editorials',
  },
  {
    __typename: 'EditorialPage',
    description: 'Description of Test1',
    pageId: '/campaigns/editorials/testtheme/test1',
    title: 'Test1',
    thumbnailUrl:
      'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=6489c814-bb96-4dab-92e1-05b1d25c43db&name=skincareguidestartscreen&inputFormat=png',
    themeTag: 'Test Theme',
  },
];
