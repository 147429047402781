/* istanbul ignore file */
import type { CategoryBoxProps } from '@ori/presentation-components';

export const categoryBoxProps: CategoryBoxProps = {
  buttonText: 'View',
  imageUrl:
    'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=856697f4-5936-4c92-8228-a017337d21a8&name=SkincareSunZoneUvProtectorFaceExposedAreasSpf50HighTheOne5in1ColourStylistLipstickGarnetAttraction23&inputFormat=jpg',
  relativeUrl: '/campaigns/editorials/testtheme',
  title: 'Skincare',
};
