import { ARTICLE_BOX_SKELETON, ArticleBoxSkeleton } from '@ori/presentation-components';
import useTestId from '@ori/testid-generator';
import times from 'lodash.times';
import { useMemo, type FC } from 'react';

import { Root } from '../EditorialCarouselSection/styles';
import { TitleSkeleton } from '../TitleSkeleton';
import { EDITORIAL_CAROUSEL_SECTION_SKELETON, NUMBER_OF_ARTICLES } from './constants';
import { CarouselSkeleton } from './styles';
import type { EditorialCarouselSectionSkeletonProps } from './types';

export const EditorialCarouselSectionSkeleton: FC<EditorialCarouselSectionSkeletonProps> = ({
  animation,
  count = NUMBER_OF_ARTICLES,
  className,
}) => {
  const { getTestId } = useTestId();

  const articles = useMemo(
    () =>
      times(count).map((_, index) => (
        <ArticleBoxSkeleton
          // eslint-disable-next-line react/no-array-index-key -- No other option for passing data to key
          key={`${ARTICLE_BOX_SKELETON}-${index}`}
          animation={animation}
        />
      )),
    [animation, count],
  );

  return (
    <Root
      data-testid={getTestId(EDITORIAL_CAROUSEL_SECTION_SKELETON)}
      className={className}
    >
      <TitleSkeleton animation={animation} />
      <CarouselSkeleton>{articles}</CarouselSkeleton>
    </Root>
  );
};
