import { Skeleton, useTheme } from '@ori-ui/mui';
import { useIsMobile } from '@ori/presentation-hooks';
import useTestId from '@ori/testid-generator';
import type { FC } from 'react';

import type { SkeletonProps } from '../types';
import { TITLE_SKELETON } from './constants';
import { Root } from './styles';

/**
 * Renders Skeleton for `Title`.
 */
export const TitleSkeleton: FC<SkeletonProps> = ({ animation = 'pulse', className }) => {
  const { getTestId } = useTestId();
  const isMobile = useIsMobile();
  const { spacing } = useTheme();

  return (
    <Root
      className={className}
      data-testid={getTestId(TITLE_SKELETON)}
    >
      <Skeleton
        variant="rectangular"
        width={isMobile ? '155px' : '205px'}
        height={isMobile ? '47px' : '62px'}
        animation={animation}
        sx={{ marginBottom: spacing(isMobile ? 6 : 10) }}
      />
    </Root>
  );
};
